@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@font-face {
    font-family: "Open_sans";
}



.body{
  background: rgb(35, 35, 35);
  background: linear-gradient(9deg, rgb(255, 255, 255) 0%,rgb(98, 98, 98)) 65%);
  width: 100vw;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.HeightContainer > *{
  font-family: "Open_sans";
  box-sizing: border-box;
}

.HeightContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.parentContainer {
  width: 50%;
  height: 50%;
  display: flex;
  background-color: rgb(212, 212, 212);
  border-radius: .5em;
  box-shadow: 10px 10px 63px 13px rgba(0,0,0,0.35);
  -webkit-box-shadow: 10px 10px 63px 13px rgba(0,0,0,0.35);
  -moz-box-shadow: 10px 10px 63px 13px rgba(0,0,0,0.35);
}

.childContainer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0em;
  border-radius: .5em;
}

.childContainer img{
  display: flex;
  height: 8em;
  width: 8em;
}

.childContainer:first-child {
  justify-content: center;
  align-items: center;
  background-color: #c4c4c4;
}

.childContainer:first-child > h3{
  text-align: center;
  font-weight: lighter;
  color: #fff;
  text-shadow: 2px 2px 1px rgba(150, 150, 150, 1);
}

.imgContainer{
  width: 100%;
  justify-content: center;
  align-content: center;
}

.forgot{
  width: 100%;
  text-align: center;
  justify-content: center;
  color: rgb(95, 95, 95);
}

.forgot:hover{
  color: #d90000;
}

.imgContainer > img {
  width: 100%;
  height: 22em;
}

.childContainer:last-child {
  gap: 2em;
  color: #fff;
  padding-bottom: 1em;
}

.childContainer:last-child > h1 {
  width: 100%;
  height: 10%;
  margin: none;
  margin-bottom: -10px;
  font-weight: bold;
  text-align: start;
  text-shadow: 2px 2px 1px rgba(150, 150, 150, 1);
  text-align: center;
}

.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 .5em;
  gap: 2em;
}


.input {
 width: 100%;
 display: flex;
 flex-wrap: wrap;
}

.title {
  width: 100%;
  letter-spacing: 1px;
  font-weight: bolder;
  text-shadow: 2px 2px 1px rgba(150, 150, 150, 1);
}

.input > input {
  width: 100%;
  padding: 2px 6px;;
  height: 2em;
  border: none;
  outline: none;
  background:none;
  border-bottom: 1px solid  #edbe23;
  transition: 250ms;
}

.input > input:hover, .input > input:focus {
  background: linear-gradient(to bottom, transparent, rgb(244, 136, 136))
}

/*START SING IN BUTTON*/

.button2 {
  background: none;
  position: relative;
  display: inline-block;
  width: 40%;
  padding: 15px 15px;
  border-radius: 4px;
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  font-family: "Open_sans", sans-serif;
  filter: hue-rotate(0deg);
  border: 2px solid #d2bdff;
  transition: all 0.1s linear;
  text-align: center;
}

.button2:hover {
  border: 2px solid transparent;
}

.button2:hover > span {
    position: absolute;
    display: block;
  }

  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  @keyframes animate3 {
    0% {
      right: -100%;
      height: 3px;
    }
    50%,
    100% {
      height: 2px;
      right: 100%;
    }
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }

.button2:hover > span:nth-child(1) {
  filter: hue-rotate(0deg);
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, transparent, #3a86ff);
  animation: animate1 1s linear infinite;
}

.button2:hover >   span:nth-child(2) {
    filter: hue-rotate(60deg);
    top: -100%;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #3a86ff);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
  }

.button2:hover > span:nth-child(3) {
    filter: hue-rotate(120deg);
    bottom: 0;
    right: 0;
    width: 100%;

    background: linear-gradient(270deg, transparent, #3a86ff);
    animation: animate3 1s linear infinite;
    animation-delay: 0.5s;
  }

.button2:hover > span:nth-child(4) {
    filter: hue-rotate(300deg);
    bottom: -100%;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #3a86ff);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
  }

/*END SIGN IN BUTTON*/

.googleLabel {
  width: 100%;
  letter-spacing: 1px;
  font-weight: bolder;
  text-align: center;
  color: rgb(95, 95, 95);
}

.googleInput {
  width: 100%;
  display: flex;
  justify-content: center;
}


.error {
  font-size: 12px;
  color: rgb(230, 84, 84);
  text-shadow: .5px .5px 0px rgba(150, 150, 150, 1);
  font-weight: bold;
}

.logError {
  width: 100%;
  font-size: 12px;
  color: rgb(230, 84, 84);
  text-shadow: .5px .5px 0px rgba(150, 150, 150, 1);
  font-weight: bold;
  text-align: center;
}

.buttons{
  display: flex;
  width: 100%;
  justify-content: center;
}

@media (max-width:900px){
  .parentContainer{
    width: 80%;
    height: 20em;
  }
  .imgContainer{
    display: none;
  }

  .childContainer img{
    margin-bottom: 0em;
  }

  .childContainer h1 {

    margin-bottom: -0px;

  }
}
@media (max-width: 768px) {

  .parentContainer{
    flex-direction: column;
    width: 80%;
    border-radius: 0em;
    height: 75%;
  }

  .childContainer{
    width: 100%;
    border-radius: 0 0 0 0 ;
    justify-content: center;
    margin-top: 1em;
  }

  .childContainer h3{
    display: none;
  }

  .childContainer:first-child{
    background: none;
  }

  .imgContainer{
    display: none;
  }
  
  .form{
    width: 70%;
  }

  .googleLabel{
    display: none;
  }

}