@import url('https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mandali&display=swap');



.flexContainer{
    min-height: 100vh;
    width: 99.1vw;
display: flex;
justify-content: center;
align-content: center;
background: rgb(45, 45, 45);
background: linear-gradient(9deg, rgb(51, 51, 51) 0%, rgb(21, 21, 21) 65%);

}

.Container{
    display: flex;
    width: 25%;
    height: 50%;
    background-color: #f9f9f9;
    flex-wrap: wrap;
    align-self: center;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.54); 
box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.54);
}

.logo{
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
}
.logo img{
    margin-top: 3em;
    width: 8em;
    height: 8em;
}

.home{
    display: flex;
    width: 100%;
    justify-content: center;
}

.form{
    display: inline-flex;
    width: 100%;
    min-height: 25em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #1d1d1d;
    margin-top: 3em;
}
.h1{
    margin: 0em 0px 0px 0px;
    color: #383838;
    font-family: 'Kdam Thmor Pro', sans-serif;
    font-size: xxx-large;
}
.label1{
    margin-top: 0em;
    position: relative;
    font-style: italic;
    font-size: small;
    color:#edbe23;
}
.divs,.divimg{
    margin: -10px;
    margin-top: 1em;
    width: 15em;
    height: 30px;
    display: flex;
    font-family: 'Mandali', sans-serif;
    font-weight: bold;
    justify-content: center;
    border: black solid 1.5px;
}
.divimg{
    width: 350px;
}
.inputs, .inputimg{
    width: 18em;
    display:inline-block;
    border: none;
    text-align: center;
    background-color: #f9f9f9;
}
.inputimg{
    width: 300px;
}
.inputimg::-webkit-file-upload-button {
    background: #f9f9f9;
    color: #1d1d1d;
    cursor: pointer;
}
.inputs::placeholder { color: rgb(51, 51, 51); }
.btns{
    display: flex;
    align-items: center;
}
.inputs:focus{
    color: #1d1d1d;
}
.send, .send2{
    width: 100px;
    height: 30px;
    margin-top: 2em;
    background: #2e2e2e;
    border: 2px solid;
    color: #d1d1d1;
    font-weight: bold;
}
.send2{
    height: 26px;
    font-size: smaller;
    display: flex;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
}
.send:hover, .send2:hover{
    color: rgb(63, 63, 63);
    cursor: pointer;
    transition: color 0.3s;
}
.eye{
    height: 20px;
    width: 24px;
    padding: 0px 3px;
}
.beye{
    /* position: relative;
    left: 10px; */
    margin-left:;
    border: none;
    border-radius: 10px;
}
.errors{
    width: 90%;
    margin-top: 1em;
    text-align: center;
    color: rgb(193, 0, 0);
    font-style: italic;
}

.errors::before + .divs{
    margin-top: 5em;
}
.good{
    background-color: #20cd008a;
    width: 90%;
    text-align: center;
    color: rgb(45, 45, 45);
    font-style: italic;
}

.policy{
    margin-top: 2em;
    width: 30em;
    text-align: center;
    justify-self: center;
    color: #1d1d1d;
}

.policy h1{
    font-size: 1em;
    font-weight: 300;
}

.policy a{
    text-decoration: none;
}

.signIn{
    display: inline-flex;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    justify-content: center;
    color: #1d1d1d;
}

.signIn h1{
    font-size: 1em;
    font-weight: 400;
}

.signIn a{
    margin-left: 1em;
}


.signInGoogle{
    width: 100%;
    text-align: center;
}

.signInGoogle h1{
    font-size: 1em;
    font-weight: 400;
}

.signInGoogle svg{
    width: 2.5em;
    height: 2.5em;
}

@media (max-width: 768px) {

    .Container{
        width: 80%;
    } 

}

