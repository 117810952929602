.Home{
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    width: 90%;
    max-width: 100%; /* Ajusta según tus necesidades */
    max-height: 100%;
    gap: 0;
    background-image: url("../../../public/img/fondosolo.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

.blob{
  z-index: 10;
}

.city{
  display: flex;
  position: fixed;
  left: 0;
  z-index: 0;
}

@media (max-width: 1266px) {
    .Home{
        width: 85%;
        margin-left: 15%;
    }
  
  }

  @media (max-width: 768px) {
    .Home{
      width: 100%;
      margin-left: 0%;
    }}