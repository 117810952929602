.flexContainer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100vh;
    z-index: 5;

}


.navBar{
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 10%;
    height: 100vh;
    background-color: rgb(223, 223, 223);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 5;

}
.image{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 8em;
    height: 8em;
    margin-top: 2em;
}

.image img{
    width: 8em;
    height: 8em;
}

html {
    scroll-behavior: smooth;
   }
   
.buttons{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 20em;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    justify-self: flex-end;
    margin-top: 4em;
}
   
.navBar a {
    text-decoration: none;
    color: rgb(32, 32, 32);
    border-bottom: 1px solid rgb(32, 32, 32);
    line-height: 3em;
    width: 8em;
    text-align: center;
    font-weight: 200;
    font-size: 1.2em;
   }
   
.navBar a:hover{
    color: rgba(213, 0, 157, 0.992);
}
   
   #down {
    margin-top: 100%;
    padding-bottom: 25%;
   } 

   svg{
       color: rgb(158, 158, 158);
       width: 2.5em;
   }
.Links{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 1.5em;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 1.4em;
}

.Links a{
    border:none;
    width: 2.5em;

}
.player{
    margin-top: 3em;
}
@media (max-width: 1266px) {
    .navBar{
        width: 15%;
    }

}

@media (max-width: 768px) {
    .flexContainer{
        display: none;
        pointer-events: none;
    }
    .navBar{
        display: none;
        pointer-events: none;
    }
    .Links{
        display: none;
        pointer-events: none;
    }
}
