.app {
  display: flex;
  width: 100vw;
  background-image: url("../public/img/fondosolo.png");
  max-width: 100%; /* Ajusta según tus necesidades */
  max-height: 100%;
  overflow:hidden ;
}
.flexColumn{
  margin-left: 10%;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.blob{
  background: rgb(0,206,255);
  background: linear-gradient(90deg, rgba(0,206,255,1) 0%, rgba(251,50,214,1) 35%, rgba(255,214,0,1) 58%, rgba(70,244,149,1) 79%, rgba(0, 221, 255, 0.774) 93%);
  height: 500px;
 aspect-ratio: 1;
  position:fixed;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  border-radius: 50% ;
  animation: rotate 20s infinite;
  z-index: 0;
  filter: blur(150px);
  pointer-events: none;
}
@keyframes rotate {
  from{
    rotate: 0deg;
  }
  30%{
    scale: .9 1.2;
  }
  40%{
    scale: 1.3 1.4;
  }
  50%{
    scale: 1 1.5;
  }
  60%{
    scale: 0.7 2;
  }
  70%{
    scale: 0.8 1.;
  }
  to{
    rotate: 360deg;
  }
}


  * {
    scrollbar-width: none;
    scrollbar-color: none;
  }

  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #000000;
    opacity: 0;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(15, 15, 15);
    border-radius: 10px;
    border: 2px solid #303030;
  }

  *::-webkit-scrollbar-button {
    height: 12px;
    background-color: #313131;
  }

  @media (max-width: 1266px) {
    .flexColumn{
        width: 85%;
        margin-left: 15%;
    }
  
  }

  @media (max-width: 768px) {
    .flexColumn{
      width: 100%;
      margin-left: 0%;
    }
}

