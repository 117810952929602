*{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flexContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  background-image: url("../../../public/img/fondo.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.description{
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 2.3em;
  height: 35em;
  z-index: 3;
}
.description h3{
  font-size: 2em;
  color: rgba(255, 255, 255, 0.721);
  line-height: 0rem;
  font-weight: 400;
  margin-left: 1.3em;
}

.glitch{
  position: relative;
  color: whitesmoke;
  font-size: 6rem;
  font-weight: bold;
  text-shadow: 
  0.05em 0 0 rgba(255,0,0,0.75),
  -0.025em -0.05em 0 rgba(0,255,0,0.75),
  0.025em 0.05em 0 rgba(0,0,255,0.75);
  -webkit-animation: glitch 600ms infinite;
          animation: glitch 600ms infinite;
  line-height: 6rem;
  margin-bottom: 0.4em;
  margin-left: 0.3em;
  z-index: 5;
}
.glitch1{
  position: relative;
  color: whitesmoke;
  font-size: 6rem;
  font-weight: bold;
  line-height: 6rem;
  margin-bottom: 0.4em;
  margin-left: 0.3em;
  z-index: 5;
}


.first {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease; 
  overflow: hidden;
}

.planeta1 {
  left: 0; /* Mueve planeta1 hacia la izquierda */
}

.planeta2 {
  left: 0; /* Mueve planeta2 hacia la izquierda */
}

.planeta3 {
  left: 0; /* Mueve planeta3 hacia la izquierda */
}

.planeta4 {
  right: 0; /* Mueve planeta4 hacia la derecha */
}

.planeta5 {
  right: 0; /* Mueve planeta5 hacia la derecha */
}

.planeta6 {
  right: 0; /* Mueve planeta6 hacia la derecha */
}

.planetas{
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.asteroidContainer {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.asteroidContainer img {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease; /* Agrega una transición suave al efecto de zoom */
}


.glitch p, .glitch1 p{
  line-height: 6rem;
  z-index: 8;
}

.glitch span, .glitch1 span{
  position: absolute;
  top:0;
  left:0;
  line-height: 6rem;
}

.glitch span:first-child {
  -webkit-animation: glitch 650ms infinite;
          animation: glitch 650ms infinite;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
          clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  -webkit-transform: translate(-0.05em, -0.0125em);
      -ms-transform: translate(-0.05em, -0.0125em);
          transform: translate(-0.05em, -0.0125em);
  opacity: .8;
}


.glitch1 span:first-child{
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
          clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  -webkit-transform: translate(-0.05em, -0.0125em);
      -ms-transform: translate(-0.05em, -0.0125em);
          transform: translate(-0.05em, -0.0125em);
  opacity: .8;
}
.glitch span:last-child .glitch1 span:last-child{
  -webkit-animation: glitch 800ms infinite;
          animation: glitch 800ms infinite;
  -webkit-clip-path: polygon(0 80%, 100% 40%, 100% 100%, 0 100%);
          clip-path: polygon(0 80%, 100% 40%, 100% 100%, 0 100%);
  -webkit-transform: translate(0.025em, 0.025em);
      -ms-transform: translate(0.025em, 0.025em);
          transform: translate(0.025em, 0.025em);
  opacity: .8;
}


.scrollDown{
  position: absolute;
  width: 2em;
  height: 2em;
  bottom: 5px;
  margin-left: 45%;
  -webkit-transform: translateY(-80px) translateX(-50%) rotate(45deg);
      -ms-transform: translateY(-80px) translateX(-50%) rotate(45deg);
          transform: translateY(-80px) translateX(-50%) rotate(45deg);
}

.scrollDown span{
  position:absolute;
  top:0;
  left:0;
  display: block;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-animation: animate 1.5s linear infinite;
          animation: animate 1.5s linear infinite
}

.scrollDown span:nth-child(1){
  -webkit-transform: translate(-15px, -15px);
      -ms-transform: translate(-15px, -15px);
          transform: translate(-15px, -15px);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.scrollDown span:nth-child(2){
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

.scrollDown span:nth-child(3){
  -webkit-transform: translate(15px, 15px);
      -ms-transform: translate(15px, 15px);
          transform: translate(15px, 15px);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

@-webkit-keyframes animate{
  0%{
    top:-5px;
    left:-5px;
    opacity: 0;
  }
  25%{
    top:0px;
    left:0px;
    opacity: 1;
  }
  50%,100%{
    top:5px;
    left:5px;
    opacity: 0;
  }
}

@keyframes animate{
  0%{
    top:-5px;
    left:-5px;
    opacity: 0;
  }
  25%{
    top:0px;
    left:0px;
    opacity: 1;
  }
  50%,100%{
    top:5px;
    left:5px;
    opacity: 0;
  }
}

@-webkit-keyframes glitch{
  0%{
    text-shadow: 
    0.05em 0 0 rgba(255,0,0,0.75),
    -0.025em -0.05em 0 rgba(0,255,0,0.75),
    0.025em 0.05em 0 rgba(0,0,255,0.75);
  }
  14%{
    text-shadow: 
    0.05em 0 0 rgba(255,0,0,0.75),
    -0.025em -0.05em 0 rgba(0,255,0,0.75),
    0.025em 0.05em 0 rgba(0,0,255,0.75);
  }
  15%{
    text-shadow: 
    -0.05em -0.025em 0 rgba(255,0,0,0.75),
    0.025em 0.025em 0 rgba(0,255,0,0.75),
    -0.05em -0.05em 0 rgba(0,0,255,0.75);
  }
  49%{
    text-shadow: 
    -0.05em -0.025em 0 rgba(255,0,0,0.75),
    0.025em 0.025em 0 rgba(0,255,0,0.75),
    -0.05em -0.05em 0 rgba(0,0,255,0.75);
  }
  50%{
    text-shadow: 
    0.025em 0.05em 0 rgba(255,0,0,0.75),
    0.025em 0 0 rgba(0,255,0,0.75),
    0 -0.05em 0 rgba(0,0,255,0.75);
  }
  99%{
    text-shadow: 
    0.025em 0.05em 0 rgba(255,0,0,0.75),
    0.025em 0 0 rgba(0,255,0,0.75),
    0 -0.05em 0 rgba(0,0,255,0.75);
  }
  100%{
    text-shadow: 
    -0.025em 0 0 rgba(255,0,0,0.75),
    -0.025em -0.025em 0 rgba(0,255,0,0.75),
    -0.025em -0.05em 0 rgba(0,0,255,0.75);
  }
  
  }

@keyframes glitch{
  0%{
    text-shadow: 
    0.05em 0 0 rgba(255,0,0,0.75),
    -0.025em -0.05em 0 rgba(0,255,0,0.75),
    0.025em 0.05em 0 rgba(0,0,255,0.75);
  }
  14%{
    text-shadow: 
    0.05em 0 0 rgba(255,0,0,0.75),
    -0.025em -0.05em 0 rgba(0,255,0,0.75),
    0.025em 0.05em 0 rgba(0,0,255,0.75);
  }
  15%{
    text-shadow: 
    -0.05em -0.025em 0 rgba(255,0,0,0.75),
    0.025em 0.025em 0 rgba(0,255,0,0.75),
    -0.05em -0.05em 0 rgba(0,0,255,0.75);
  }
  49%{
    text-shadow: 
    -0.05em -0.025em 0 rgba(255,0,0,0.75),
    0.025em 0.025em 0 rgba(0,255,0,0.75),
    -0.05em -0.05em 0 rgba(0,0,255,0.75);
  }
  50%{
    text-shadow: 
    0.025em 0.05em 0 rgba(255,0,0,0.75),
    0.025em 0 0 rgba(0,255,0,0.75),
    0 -0.05em 0 rgba(0,0,255,0.75);
  }
  99%{
    text-shadow: 
    0.025em 0.05em 0 rgba(255,0,0,0.75),
    0.025em 0 0 rgba(0,255,0,0.75),
    0 -0.05em 0 rgba(0,0,255,0.75);
  }
  100%{
    text-shadow: 
    -0.025em 0 0 rgba(255,0,0,0.75),
    -0.025em -0.025em 0 rgba(0,255,0,0.75),
    -0.025em -0.05em 0 rgba(0,0,255,0.75);
  }
  
  }

  @media (prefers-reduced-motion: reduce){
    *,
    ::before,
    ::after{
      -webkit-animation-delay: -1ms ;
              animation-delay: -1ms ;
      -webkit-animation-duration: 1ms ;
              animation-duration: 1ms ;
      -webkit-animation-iteration-count: 1 ;
              animation-iteration-count: 1 ;
      background-attachment: initial ;
      scroll-behavior: auto ;
      -webkit-transition-duration: 0s ;
           -o-transition-duration: 0s ;
              transition-duration: 0s ;
      -webkit-transition-delay: 0s ;
           -o-transition-delay: 0s t;
              transition-delay: 0s ;
    }
  }

  @media (max-width: 768px) {
    .flexContainer {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
              background-attachment: local;
              background-image: url("../../../public/img/fondophone.png");
              width: 100vw;
              height: 100vh;
              background-size: 100% 100%;
              background-position: center;
              background-repeat: none;
      overflow: hidden;
      z-index: 3;
      
    }
  
    .description {
      width: 100%;
    }
  
    .description h3 {
      line-height: 2rem;
    }
  
    .glitch {
      font-size: 5em;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }
  
    .canvas {
      height: 100%;
    }
  
    .scrollDown {
      margin-left: 10%;
    }
  
    .first {
      height: 50%;
      width: 100%;
      overflow: hidden;
      overflow-x: hidden;
      bottom: 0;
    }
  
    .asteroidContainer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      height: 50%;
      width: 100%;
      rotate: 90deg;
      bottom: 0;
      overflow: hidden;
    }

    .asteroidContainer img{
      height: 100%;
      
    }
  }