

.flexContainer{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
}

.flexContainer2{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
flex-direction: column;
  position: absolute;
  width: 90%;
}


.grid{
  display: grid;
  width: 90%;
  min-height: 1em;
  grid-template-columns: repeat(3, 22%);
  grid-auto-rows: 45em;
  margin-bottom: 10em;
  justify-content: center;
  margin-top: 2em;
  gap: 8em;
  align-self: center;
}

.link{text-decoration: none;}


.title{
  color: rgb(251, 251, 251);
  margin-left: 5em;
  width: 30em;
  margin-top: 8em;
}
.title p{
  opacity: 1;
}


.glitch {
  position: relative;
  font-size: 6em;
  font-weight: bold;
  line-height: 6rem;
}


.glitch span{
  position: absolute;
  top:0;
  left:0;
  line-height: 6rem;
}

.glitch span:first-child{
  -webkit-clip-path: polygon(0 0, 0% 0, 100% 45%, 0 45%);
          clip-path: polygon(0 0, 0% 0, 100% 45%, 0 45%);
  -webkit-transform: translate(-0.02em, -0.0125em);
      -ms-transform: translate(-0.02em, -0.0125em);
          transform: translate(-0.02em, -0.0125em);
  opacity: .8;
}
.glitch span:last-child{
  -webkit-clip-path: polygon(0 80%, 100% 40%, 100% 100%, 0 100%);
          clip-path: polygon(0 80%, 100% 40%, 100% 100%, 0 100%);
  -webkit-transform: translate(0.03em, 0.025em);
      -ms-transform: translate(0.03em, 0.025em);
          transform: translate(0.03em, 0.025em);
  opacity: .8}

.descriptionContainer{
width: 100%;
}

.description{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
    width: 100%;
    justify-content: center;
            gap:5em;
            text-align: center;
  }
  
  .description p{
    color:rgb(255, 255, 255);
    font-size: 1.3em;
  }
  
  .description a{
    text-decoration: none;
    color: rgba(213, 0, 156, 0.693);
  }
  
  .description a:hover{
    color: rgba(213, 0, 157, 0.992);
  }
  
  .description h1{
    color: rgba(19, 19, 19);
    font-size: 1.4em;
    margin-top: 1.5em;
  }
  
  
  .category{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 25em;
    height: 40em;
    border-radius: 1em;
}

.img{
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 10em;
    justify-content: center;
}

 .img img{
    display: flex;
    width: 50%;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
}

.name h1, .name2 h1{
  font-size: 1.6em;
  margin-top: 1em !important;
  color: #fffff6;
  }
  
  .name h2, .name2 h2{
  font-size: 1.4em;
  color: #fffff6;
  margin-top: 0;
  }
  
  

  .name{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 20%;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 2em;
      border-bottom-right-radius: 2em;
      gap: 0em !important;
  
  }
  

  .image{
    display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20em;
  height: 15em;
  }
  
  .taller img{
    width: 12em;
    height: 7em;
    background-color: #f0d138;
    border-radius: 1em;
    }
  
  .codeLearn p, .pokemon p, .taller p{
  line-height: 1em;
  font-size: 2em;
  font-weight: 300;
  height: 2em;
  position: relative;
  color: rgb(236, 236, 236);
  }
  
  
  .codeLearn h1, .pokemon h1, .taller h1{
  font-weight: 200;
  color: rgb(236, 236, 236);
  }
  
  .text{
    display: flex;
    position: relative;
    justify-content: center;
    height: 30%;
    width: 100%;
  }
.scrollDown{
  position: absolute;
  width: 2em;
  height: 2em;
  margin-top: 57em;
  margin-left: 45%;
  -webkit-transform: translateY(-80px) translateX(-50%) rotate(45deg);
      -ms-transform: translateY(-80px) translateX(-50%) rotate(45deg);
          transform: translateY(-80px) translateX(-50%) rotate(45deg);
}

.scrollDown span{
  position:absolute;
  top:0;
  left:0;
  display: block;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-animation: animate 1.5s linear infinite;
          animation: animate 1.5s linear infinite
}

.scrollDown span:nth-child(1){
  -webkit-transform: translate(-15px, -15px);
      -ms-transform: translate(-15px, -15px);
          transform: translate(-15px, -15px);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.scrollDown span:nth-child(2){
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

.scrollDown span:nth-child(3){
  -webkit-transform: translate(15px, 15px);
      -ms-transform: translate(15px, 15px);
          transform: translate(15px, 15px);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}


.category2{
  display: flex;
  flex-direction: column;
  width: 15em;
  height: 40em;
  border-radius: 1em;
  background: none;
  align-self: center;
}
.img2 svg{
  display: flex;
  width: 15em;
  height: 15em;
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  border: 1px solid black;
  border-bottom: none;
  color: white;
}
.name2{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20%;
  align-items: center;
  justify-content: center;
  background-color: #373737;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
  border: 1px solid black;
  border-top: none;
}

@-webkit-keyframes animate{
  0%{
    top:-5px;
    left:-5px;
    opacity: 0;
  }
  25%{
    top:0px;
    left:0px;
    opacity: 1;
  }
  50%,100%{
    top:5px;
    left:5px;
    opacity: 0;
  }
}

@keyframes animate{
  0%{
    top:-5px;
    left:-5px;
    opacity: 0;
  }
  25%{
    top:0px;
    left:0px;
    opacity: 1;
  }
  50%,100%{
    top:5px;
    left:5px;
    opacity: 0;
  }
}



@media (max-width: 768px) {
  .flexContainer{
    justify-content: center;
    margin-left: 0em;
    position: relative;
  }
  .flexContainer2{
    margin-left: 0;
    width: 90%;
  }
  .description{
    flex-direction: column;
    margin-left: 0;

  }
  .category{
    width: 100%;
    align-self: center;
  }
  .grid{
    width: 90%;
  }

  .description h3{
    line-height: 2rem;
    margin-left: 0;
  }

  .glitch{
    font-size: 3em;
    margin-left: 0.3em;
    width: fit-content;
  }

  .title{
    margin-left: 0;
    width: 80%;
    top: 0;
  }

  .canvas{
    height: 100%;
    width: 100%;
  }
  .scrollDown{
    margin-left: 10%;
  }
  .descriptionContainer{
    width: 100vw;
  }

  .grid{
    display: flex;
    width: 90%;
    flex-direction: column;
 justify-content: center;
    gap: 3em;
    margin-top: 57em;
    align-self: center;
    position: relative;
  }
  .category2{
    position: relative;
margin-top:50em;
  }
}
