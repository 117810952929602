.container{
    display: flex;
    position: fixed;
    top: 00%;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.427);
}

.flexContainer{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 60%;
    height: 50em;
    background-color: white;
    box-shadow: 10px 10px 63px 13px rgba(0,0,0,0.35);
-webkit-box-shadow: 10px 10px 63px 13px rgba(0,0,0,0.35);
-moz-box-shadow: 10px 10px 63px 13px rgba(0,0,0,0.35);
border-radius: .8em;
align-items: center;
overflow: scroll;
}

.flexContainer h1{
    font-weight: 300;
}

.flexContainer span{
    display: flex;
    width: 10em;
    height: 1px;
    background-color: black;
}

.form{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    color: black;
    gap: 1em;
    margin-top: 2em;
}

.title{
    font-size: 1.5em;
}

.button2{
    width: 10em;
}

.close{
    display: flex;
    position: absolute;
    float: right;
    right: 1em;
    top: 1em;
    background-color: rgb(186, 1, 1);
}

@media (max-width: 768px) {
    .flexContainer{
        width: 90%;
    }

    .form{
        width: 80%;
    }
}