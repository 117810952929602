
.flexContainer{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.flexContainer2{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: absolute;
  width: 90%;
}


.title{
  color: rgb(251, 251, 251);
  margin-left: 5em;
  width: 30em;
  margin-top: 8em;
}
.title p{
  opacity: 1;
}


.glitch {
  position: relative;
  font-size: 6em;
  font-weight: bold;
  line-height: 6rem;
}


.glitch span{
  position: absolute;
  top:0;
  left:0;
  line-height: 6rem;
}

.glitch span:first-child{
  -webkit-clip-path: polygon(0 0, 0% 0, 100% 45%, 0 45%);
          clip-path: polygon(0 0, 0% 0, 100% 45%, 0 45%);
  -webkit-transform: translate(-0.02em, -0.0125em);
      -ms-transform: translate(-0.02em, -0.0125em);
          transform: translate(-0.02em, -0.0125em);
  opacity: .8;
}
.glitch span:last-child{
  -webkit-clip-path: polygon(0 80%, 100% 40%, 100% 100%, 0 100%);
          clip-path: polygon(0 80%, 100% 40%, 100% 100%, 0 100%);
  -webkit-transform: translate(0.03em, 0.025em);
      -ms-transform: translate(0.03em, 0.025em);
          transform: translate(0.03em, 0.025em);
  opacity: .8}


.description{
  width: 39em;
  margin-left: 8em;
}

.description p{
  color: white;
  font-size: 1.3em;
}

.description a{
  text-decoration: none;
  color: rgba(213, 0, 156, 0.693);
}

.description a:hover{
  color: rgba(213, 0, 157, 0.992);
}

.description h1{
  color: azure;
  font-size: 1.4em;
  margin-top: 1.5em;
}

.img{
  width: 25em;
  height: 25em;
  border-radius:50% ;
  margin-left: 15em;
  margin-top: 15em;
}

.progress{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  margin-top: 15em;
  margin-left: 10em;
  width: 40%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: center;
      align-content: center;
}

.progress p{
  font-size: 1.4em;
}

.progressBar{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgb(37, 37, 37);
  border: 1px solid rgb(68, 68, 68);
  border-radius: .5em;
  width: 100%;
  height: 1em;
}

.progressFront{
  border-radius: .5em;
  height: 100%;
  background-color: rgb(207, 35, 255);
  width: 93%;
}
.progressBack{
  border-radius: .5em;
  height: 100%;
  background-color: rgb(207, 35, 255);
  width:86%;
}

.progressReact{
  border-radius: .5em;
  height: 100%;
  background-color: rgb(207, 35, 255);
  width: 90%;
}
.scrollDown{
  position: absolute;
  width: 2em;
  height: 2em;
  margin-top: 57em;
  margin-left: 45%;
  -webkit-transform: translateY(-80px) translateX(-50%) rotate(45deg);
      -ms-transform: translateY(-80px) translateX(-50%) rotate(45deg);
          transform: translateY(-80px) translateX(-50%) rotate(45deg);
}

.scrollDown span{
  position:absolute;
  top:0;
  left:0;
  display: block;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-animation: animate 1.5s linear infinite;
          animation: animate 1.5s linear infinite
}

.scrollDown span:nth-child(1){
  -webkit-transform: translate(-15px, -15px);
      -ms-transform: translate(-15px, -15px);
          transform: translate(-15px, -15px);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.scrollDown span:nth-child(2){
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

.scrollDown span:nth-child(3){
  -webkit-transform: translate(15px, 15px);
      -ms-transform: translate(15px, 15px);
          transform: translate(15px, 15px);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

@-webkit-keyframes animate{
  0%{
    top:-5px;
    left:-5px;
    opacity: 0;
  }
  25%{
    top:0px;
    left:0px;
    opacity: 1;
  }
  50%,100%{
    top:5px;
    left:5px;
    opacity: 0;
  }
}

@keyframes animate{
  0%{
    top:-5px;
    left:-5px;
    opacity: 0;
  }
  25%{
    top:0px;
    left:0px;
    opacity: 1;
  }
  50%,100%{
    top:5px;
    left:5px;
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .canvas{
    height:65em;
  }
  .flexContainer2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    
  }

  .description{
    margin-left: 0;
    font-size: 1em;
    width: 100%;
    text-align: center;

  }

  .descriptionContainer{
    width: 100vw;
  }

  .glitch{
    font-size: 4em;
      width: fit-content;
      margin-left: 0.3em;
  }

  .title{
    margin-left: 0;
    width: 80%;
  }

  .scrollDown{
    margin-top: 62em;
    margin-left: 50%;
  }

  .progress{
    margin-top: 5em;
    margin-left: 3em;
    justify-self: center;
    align-self: center;
    width: 100%;
  }


}
